export default {
  /**
   * @param currency {number}
   * @returns {String}
   *
   * param 5000
   * return $5.000
   */
  methods: {
    formatCurrency (currency) {
      return this.$options.filters.currency(currency, '$', 0, { thousandsSeparator: '.' })
    }
  }
}
