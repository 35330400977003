<template>
  <v-container :style="{'background-color': ($vuetify.theme.dark ? '#1e1e1e' : '#ffffff'),
                        'color': ($vuetify.theme.dark ? '#ffffff' : '#1e1e1e')}">
    <!--- Dialogs--->
    <ApproveEmptyCartDialog v-model="showApproveEmptyCartDialog" @approved="confirmEmptyCart" />
    <ErrorDialog :message="errorDialog.message" v-model="errorDialog.show" />
    <ApprovedDialog v-model="showApprovedDialog" @approved="goToPurchases" purchaseType="product" />
    <AddNewCreditCardDialog
      v-if="showNewCreditCardDialog"
      v-model="showNewCreditCardDialog"
      @creditCardAdded="() => {}"
      @cancelTransaction="isPaying = false"
    />
    <SelectPaymentMethodDialog
      v-model="showSelectPaymentMethodDialog"
      :selectedPaymentMethod="selectedPaymentMethod"
      :currency="venueData.currency"
      :totalPriceCartProducts="totalPriceCartProducts"
      @setSelectedPaymentMethod="setSelectedPaymentMethod"
      @confirmSelectedPaymentMethod="confirmPurchase"
      @cancelTransaction="isPaying = false"
    />
    <CreditCardsForPaymentDialog
      v-model="showCreditCardsForPaymentDialog"
      :totalPayAmount="totalPriceCartProducts"
      @cardSelected="confirmPurchase"
      @openAddNewCreditCardDialog="showNewCreditCardDialog = true"
      @cancelTransaction="isPaying = false"
    />
    <!-- PSE or Cash User Payment Info -->
    <SetPaymentInfoDialog
      v-model="showSetPaymentInfoDialog"
      @setPaymentInfo="setPaymentInfo"
      @confirmPaymentInfo="confirmPurchase"
      @cancelTransaction="isPaying = false"
    />
    <!--- end Dialogs--->
    <!-- start spiner --->
    <v-row align="center" class="pt-10" justify="center" v-if="isLoadingPlace">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <!-- end -->
    <v-col cols="12" @click="goToPlaceDetail" class="place-info">
      <div class="d-flex align-center">
        <v-img
          :src="$vuetify.theme.dark ? 'https://storage.googleapis.com/keybe/kb.live/img/venues_list_restaurant.svg'
                                    : 'https://storage.googleapis.com/keybe/kb.live/img/venues_list_restaurant-grey.svg'"
          width="24px"
          max-width="24px"
          height="30px"
          max-height="30px"
          class="mr-4"
        ></v-img>
        <h1>{{venueData.name || venueData.title}}</h1>
      </div>
    </v-col>
    <v-col cols="12" class="product-info">
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <div :class="['d-flex', 'align-center', 'kbGray', 'rounded-t-lg']">
            <v-col class="px-4">
              <h2>{{ $tc('products', 0) }}</h2>
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" class="py-0">
          <template v-for="(product, index) in productCartItems">
            <div :key="`product-cart-${index}`"
              class="d-flex align-center kbGray rounded-lg px-1"
              style="margin-top: 1px;"
            >
              <v-col
                v-if="product.images && Array.isArray(product.images) && product.images.length"
                class="flex-grow-0 flex-shrink-1"
              >
                <v-img
                  :src="product.images[0]"
                  width="41px"
                  height="41px"
                ></v-img>
              </v-col>
              <v-col
                class="flex-grow-1 flex-shrink-0"
              >
                <h3 class="mb-2">{{ product.name }}</h3>
                <div class="d-flex align-center">
                  <h3>{{ product.price && formatCurrency(product.price) || 0 }} x {{product.numberProducts}}</h3>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mx-2"
                    color="kbBlue"
                    outlined
                    x-small
                    @click="subtractProductFromCart(index, product)"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    color="kbBlue"
                    outlined
                    x-small
                    @click="addProductToCart(index, product)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </div>
          </template>
        </v-col>
        <v-col cols="12" class="pt-4 pb-0">
          <div :class="['d-flex', 'align-center', 'kbGray', 'rounded-t-lg']">
            <v-col class="px-4">
                <div class="d-flex align-center">
                  <img
                    :src="$vuetify.theme.dark ? 'https://storage.googleapis.com/keybe/kb.live/img/bottom_bar_products_cart-receipt.svg'
                                              : 'https://storage.googleapis.com/keybe/kb.live/img/bottom_bar_products_cart-receipt-grey.svg'"
                    class="mr-4"
                  >
                  <h2>{{ $t('toPay') }}:</h2>
                  <v-spacer />
                  <h2>{{ formatCurrency(totalPriceCartProducts) }}</h2>
                </div>
            </v-col>
          </div>
        </v-col>
        <!-- <v-col cols="12" class="pt-1 pb-0">
          <div
            :class="['kbGray', 'rounded-b-lg']"
          >
            <v-col>
              <h4 class="mb-2">
                PROPINA:
              </h4>
              <div :class="['d-flex', 'align-center', 'mb-2',
                $vuetify.breakpoint.mobile && 'justify-space-between']">
                <v-btn
                  color="kbBlue"
                  class="mr-2"
                  small
                  outlined
                >
                  10 %
                </v-btn>
                <v-btn
                  color="kbBlue"
                  class="mr-2"
                  small
                  outlined
                >
                  15 %
                </v-btn>
                <v-btn
                  color="kbBlue"
                  small
                  outlined
                >
                  20 %
                </v-btn>
              </div>
              <v-text-field
                label="Cupón de descuento / Gift card"
                solo
                clearable
                background-color="kbLittleGray"
              ></v-text-field>
              <div class="d-block text-right">
                <div class="mb-1">TIP: $8.888</div>
                <div class="mb-1">- Discount: $8.888</div>
                <div class="mb-1">TAX: $8.888</div>
                <div class="text-h6 font-weight-bold">TOTAL: $8.888</div>
              </div>
            </v-col>
          </div>
        </v-col> -->
        <!-- <v-col cols="12" class="pt-2 pb-0">
          <div :class="['d-flex', 'align-center', 'kbGray', 'rounded-t-lg']">
            <v-col class="px-4">
                <div class="d-flex align-center">
                  <img
                    src="https://storage.googleapis.com/keybe/kb.live/img/place_purchase-payment_method.svg"
                    class="mr-4"
                  >
                  <h2>{{ $t('paymentMethod') }}</h2>
                </div>
            </v-col>
          </div>
        </v-col> -->
        <v-col cols="12" class="pb-0"
          style="padding-top: 1px;"
        >
          <div
            :class="['kbGray', 'rounded-b-lg']"
          >
            <v-col class="px-4">
              <!-- <div :class="['d-flex', 'align-center', 'mb-2',
                $vuetify.breakpoint.mobile && 'justify-space-between']">
                <v-btn
                  color="kbBlue"
                  class="mr-2"
                  small
                  outlined
                >
                  TC
                </v-btn>
                <v-btn
                  color="kbBlue"
                  class="mr-2"
                  small
                  outlined
                >
                  PSE
                </v-btn>
                <v-btn
                  color="kbBlue"
                  small
                  outlined
                >
                  {{ $t('balance') }}
                </v-btn>
              </div>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Número"
                    clearable
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="MM/YY"
                    clearable
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="CSV"
                    clearable
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre"
                    clearable
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row> -->
              <v-btn
                class="text-uppercase"
                color="kbBlue"
                style="width: 100%;"
                x-large
                @click="confirmPurchase"
              >
                <!-- {{ $t('pay') }} -->
                {{ $t('continue') }}
              </v-btn>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import notifications from '@/plugins/notifications'
import formatCurrency from '@/mixins/formatCurrency'
import ApproveEmptyCartDialog from '@/pages/Venues/VenuePlace/Dialogs/ApproveEmptyCartDialog'
import ErrorDialog from '@/pages/Venues/VenueEvent/Dialogs/ErrorDialog'
import ApprovedDialog from '@/components/Dialogs/ApprovedDialog'
// import AddNewStripeCreditCardDialog from '@/pages/Venues/VenueEvent/Dialogs/AddNewStripeCreditCardDialog'
import AddNewWompiCreditCardDialog from '@/pages/Venues/VenueEvent/Dialogs/AddNewWompiCreditCardDialog'
// AddNewCreditCardByMercadopagoDialog,
import CreditCardsForPaymentDialog from '@/pages/Venues/VenueEvent/Dialogs/CreditCardsForPaymentDialog'
import SelectPaymentMethodDialog from '@/pages/Venues/VenueEvent/Dialogs/SelectPaymentMethodDialog/SelectPaymentMethodDialog'
import SetPaymentInfoDialog from '@/pages/Venues/VenueEvent/Dialogs/SetPaymentInfoDialog'

export default {
  components: {
    ApproveEmptyCartDialog,
    ErrorDialog,
    ApprovedDialog,
    AddNewCreditCardDialog: AddNewWompiCreditCardDialog,
    CreditCardsForPaymentDialog,
    SelectPaymentMethodDialog,
    SetPaymentInfoDialog
  },
  mixins: [ formatCurrency ],
  async created () {
    try {
      await this.getPlaceInfo()
      if (this.userInfo) {
        this.getUserCreditCards()
      }
    } catch (error) {
      console.error(error)
    }
  },
  data () {
    return {
      isLoadingPlace: true,
      isLoadingPurchase: false,
      showApproveEmptyCartDialog: false,
      showSelectPaymentMethodDialog: false,
      showApprovedDialog: false,
      showNewCreditCardDialog: false,
      showCreditCardsForPaymentDialog: false,
      showSetPaymentInfoDialog: false,
      isPaying: false,
      selectedPaymentMethod: '',
      paymentUserInfo: {},
      errorDialog: {
        message: '',
        show: false
      }
    }
  },
  computed: {
    ...mapState({
      selectedPlace: state => state.places.selectedPlace,
      selectedPlaceId: state => state.storedStates.selectedPlaceId,
      productCartItems: state => state.shoppingCart.productCartItems,
      creditCards: state => state.money.creditCards,
      userInfo: state => state.authKeybe.userInfo,
      isLoggedIn: state => state.authKeybe.isLoggedIn
    }),
    ...mapGetters('shoppingCart', [ 'totalPriceCartProducts' ]),
    venueData () {
      return {
        ...this.selectedPlace
      }
    },
    selectedCreditCard: {
      get () {
        return this.$store.state.money.selectedCreditCard
      },
      set (value) {
        this.$store.commit('money/SET_SELECTED_CREDIT_CARD', value)
      }
    }
  },
  methods: {
    async getPlaceInfo () {
      this.$store.commit('storedStates/SET_SELECTED_VENUE', 'Place')
      const { id } = this.$route.params
      if (id !== this.selectedPlaceId) {
        console.log('id not equals to selectedPlaceId')
        console.log('id')
        console.log(id)
        console.log('this.selectedPlaceId')
        console.log(this.selectedPlaceId)
        await this.$store.commit('storedStates/SET_SELECTED_PLACE_ID', id)
      }
      try {
        await this.$store
          .dispatch('places/getPlace', this.selectedPlaceId)
        this.isLoadingPlace = false
      } catch (error) {
        console.error(error)
        this.isLoadingPlace = false
      }
    },
    getUserCreditCards () {
      this.$store.dispatch('money/getUserCards')
    },
    goToPlaceDetail () {
      this.$router.push({
        name: 'VenuePlaceDetail',
        params: { id: this.$route.params.id }
      })
    },
    goToPurchases () {
      this.$router.push({ name: 'Purchases' })
    },
    openKeybeUi () {
      window.location.hash = '/kb-profile'
      // window.keybe.uiOpenModal()
    },
    addProductToCart (index, product) {
      product.numberProducts += 1
      this.$store.commit('shoppingCart/SET_PRODUCT_CART_ITEM', { index, product })
    },
    subtractProductFromCart (index, product) {
      let newNumberProducts = product.numberProducts - 1
      if (newNumberProducts === 0) {
        if (this.productCartItems.length === 1) {
          this.showApproveEmptyCartDialog = true
        } else {
          this.$store.commit('shoppingCart/REMOVE_PRODUCT_FROM_CART', index)
        }
      } else {
        product.numberProducts -= 1
        this.$store.commit('shoppingCart/SET_PRODUCT_CART_ITEM', { index, product })
      }
    },
    confirmEmptyCart () {
      this.$store.commit('shoppingCart/CLEAR_CART_ITEMS')
      this.goToPlaceDetail()
    },
    setSelectedPaymentMethod (selectedPaymentMethod) {
      this.selectedPaymentMethod = selectedPaymentMethod
    },
    setPaymentInfo (paymentInfo) {
      this.paymentUserInfo = paymentInfo
    },
    async confirmPurchase () {
      try {
        if (!this.isPaying) {
        // Reset variables
          this.selectedPaymentMethod = ''
          this.paymentUserInfo = {}
          this.selectedCreditCard = {}
        }
        this.isPaying = true
        if (!this.isLoggedIn) {
          notifications.info(this.$t('pleaseLoginBeforeConfirmPayment'))
          this.openKeybeUi()
          return
        }
        if (this.totalPriceCartProducts === 0) {
          notifications.info(this.$t('youHaveNotAddedProductsYet'))
          this.confirmEmptyCart()
          return
        }
        console.log('this.selectedPaymentMethod')
        console.log(this.selectedPaymentMethod)
        console.log('this.venueData.orderType')
        console.log(this.venueData.orderType)
        if (this.venueData.orderType !== 'nopaidEcommerce' &&
          this.venueData.orderType !== 'nopaidWebchat' &&
          this.venueData.orderType !== 'nopaidWhatsapp' &&
          this.venueData.orderType !== 'nopaidPos' &&
          this.venueData.orderType !== 'nopaidOther'
        ) {
          // Cobrar
          // show modal select payment method
          if (!this.selectedPaymentMethod) {
            notifications.info(this.$t('whichPaymentMethodWillBeUsed'))
            this.showSelectPaymentMethodDialog = true
            return
          }
          if (this.selectedPaymentMethod === 'creditCard') {
          // validate user has credit cards:
            if (!this.creditCards.length) {
              notifications.info(this.$t('youHaveNotAddedCreditCardsYet'))
              // TODO: Mostrar el keybe-profile->money->addCreditCard
              // user has no credit cards associated
              this.showNewCreditCardDialog = true
              return
            }

            // console.log('this.selectedCreditCard')
            // console.log(this.selectedCreditCard)
            if (!this.selectedCreditCard.id) {
              notifications.info(this.$t('whichCardWillBeUsed'))
              this.showCreditCardsForPaymentDialog = true
              return
            }
          }

          if (this.selectedPaymentMethod === 'pse') {
            // validate user has filled payment information:
            if (!this.paymentUserInfo.email ||
            !this.paymentUserInfo.docType ||
            !this.paymentUserInfo.docNumber ||
            !this.paymentUserInfo.typePerson ||
            !this.paymentUserInfo.bank) {
              this.$store.dispatch('money/getPseBanks')
              this.$store.dispatch('money/getIdentificationTypes')
              this.$store.dispatch('money/getAcceptanceToken')
              this.showSetPaymentInfoDialog = true
              return
            }
          }
          this.isLoadingPurchase = true
          let purchaseConfirmation = {
            paymentMethod: this.selectedPaymentMethod
          }
          if (this.selectedPaymentMethod === 'creditCard') {
            purchaseConfirmation = {
              ...purchaseConfirmation,
              dues: 1,
              cardId: this.selectedCreditCard.id
            }
          } else if (this.selectedPaymentMethod === 'pse') {
            purchaseConfirmation = {
              ...purchaseConfirmation,
              email: this.paymentUserInfo.email,
              docType: this.paymentUserInfo.docType,
              docNumber: this.paymentUserInfo.docNumber,
              typePerson: this.paymentUserInfo.typePerson,
              urlResponse: `${window.location.protocol}//${window.location.host}/purchases`,
              bank: this.paymentUserInfo.bank
            }
          } else if (this.selectedPaymentMethod === 'cash') {
            purchaseConfirmation = {
              ...purchaseConfirmation

            }
          } else if (this.selectedPaymentMethod === 'mercadopago') {
            purchaseConfirmation = {
              ...purchaseConfirmation

            }
          }
          await this.$store.commit('money/SET_PURCHASE_CONFIRMATION', purchaseConfirmation)
          // console.log('purchaseConfirmation')
          // console.log(purchaseConfirmation)
          try {
            const productPurchaseResponse = await this.$store.dispatch('shoppingCart/setProductPurchase')
            if (this.totalPriceCartProducts > 0) {
              const response = await this.$store.dispatch('shoppingCart/setProductPurchasePayment', productPurchaseResponse)
              // console.log('response')
              // console.log(response)
              this.isLoadingPurchase = false
              this.isPaying = false
              if (this.selectedPaymentMethod === 'creditCard') {
                if (response.charge.status.code === 1) {
                  this.showApprovedDialog = true
                } else if (response.charge.status.code === 2) {
                  notifications.error('Lamentamos informar que tu compra fue rechazada')
                } else {
                // notificacion de estado pendiente
                  notifications.success('En contados segundos obtendrás la confirmación de tu compra en tu bandeja de correo electrónico')
                  return this.goToPurchases()
                }
              } else if (this.selectedPaymentMethod === 'pse') {
                if (response &&
                response.charge &&
                response.charge.payment_method &&
                response.charge.payment_method.extra &&
                response.charge.payment_method.extra.async_payment_url
                ) {
                  console.log('response.charge:')
                  console.log(response.charge)
                  // window.open(response.charge.payment_method.extra.async_payment_url, '_blank')
                  window.location.replace(response.charge.payment_method.extra.async_payment_url)
                } else {
                // notificacion de error
                  return notifications.error('Hubo un error procesando tu compra')
                }
              } else if (this.selectedPaymentMethod === 'cash') {
              } else if (this.selectedPaymentMethod === 'mercadopago') {
              }
            } else {
              this.showApprovedDialog = true
            }
          } catch (error) {
            console.log('error:')
            console.error(error)
            console.log(JSON.stringify(error))
            this.isLoadingPurchase = false
            this.isPaying = false

            this.errorDialog = {
              message: error.message,
              show: true
            }
          }
        } else {
          // No cobrar
          try {
            const productPurchaseResponse = await this.$store.dispatch('shoppingCart/setProductPurchase')
            console.log('productPurchaseResponse')
            console.log(productPurchaseResponse)
            this.showApprovedDialog = true
            if (this.venueData.orderType === 'nopaidWebchat') {
              // if (productPurchaseResponse.orderTypeActionResult &&
              //   productPurchaseResponse.orderTypeActionResult.messageHasBeenSent) {
              //   window.keybe.openChat()
              // }
              // Open webchat
              window.keybe.openChat()
            }
            if (this.venueData.orderType === 'nopaidWhatsapp') {
              if (productPurchaseResponse.orderTypeActionResult &&
                productPurchaseResponse.orderTypeActionResult.whatsappMessage) {
                window.open(productPurchaseResponse.orderTypeActionResult.whatsappMessage, '_blank')
              }
            }
          } catch (error) {
            console.log('error:')
            console.error(error)
            this.isLoadingPurchase = false
            this.isPaying = false

            this.errorDialog = {
              message: error.message,
              show: true
            }
          }
        }
        if (this.showApprovedDialog) {
          this.$store.commit('shoppingCart/CLEAR_CART_ITEMS')
        }
      } catch (error) {
        console.log('error:')
        console.error(error)
        this.isLoadingPurchase = false
        this.isPaying = false

        this.errorDialog = {
          message: this.$t('errorOnPurchase'),
          show: true
        }
      }
    }
  },
  watch: {
    async selectedPlaceId (val) {
      await this.getPlaceInfo()
    },
    userInfo (user) {
      if (user) {
        this.getUserCreditCards()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.place-info {
  cursor: pointer;
}
.product-info {
  ::v-deep .v-icon {
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
