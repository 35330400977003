<template>
  <v-dialog v-model="show" max-width="500px" persistent>
    <v-card style="border-radius: 6px">
      <v-card-text>
        <v-row no-gutters>
          <v-col class="text-center my-3" cols="12">
            <!-- <h1 class="main-title">{{ $t("great") }}</h1> -->
            <p class="pt-3">{{ '¿Deseas vaciar el carrito y volver al restaurante?' }}</p>
          </v-col>

          <v-col class="text-center" cols="12">
            <v-btn @click="close" large text
              class="mr-4"
            >
              {{ 'Cancelar' }}
            </v-btn>
            <v-btn @click="approve" large outlined>
              {{ 'Si' }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  props: {
    value: {
      type: Boolean
    }
  },
  methods: {
    close () {
      this.show = false
    },
    approve () {
      this.$emit('approved')
      this.close()
    }
  }
}
</script>
